const aboutTop = function () {
  setTimeout(() => {
    document.body.scrollTop;
    panelChanging = false;
    document.documentElement.setAttribute(`style`, `scroll-behavior: auto;`);
  }, 0);

  const value = document.querySelector<HTMLElement>(`.value`);
  const valueCards: NodeListOf<HTMLElement> =
    document.querySelectorAll<HTMLElement>(`.value-card`);
  let zIndexNumber = 6;
  valueCards!.forEach((element) => {
    element.addEventListener(`click`, () => {
      element.classList.toggle(`--flip`);
      zIndexNumber++;
      setTimeout(() => {
        element.style.zIndex = String(zIndexNumber);
      }, 300);
    });
  });

  const panelsWrapper = document.querySelector<HTMLElement>(`.panels-wrapper`);
  const valueBuffer = 300;
  let panelState = 1;
  let panelChanging = true;
  let lastScrollTop = 0;
  const distanceToValue = 0;

  const scrollEvent = (direction: string) => {
    panelChanging = true;
    const additionalNumber = direction === `down` ? 1 : -1;
    if (panelState === 3) {
      setTimeout(() => {
        if (direction === `down`) value.classList.add(`--show`);
      }, 500);
    }
    panelsWrapper.dataset.showPanel = String(panelState + additionalNumber);
    setTimeout(() => {
      panelState += additionalNumber;
      window.scrollTo(0, innerHeight * (panelState - 1));
      lastScrollTop = pageYOffset;
      setTimeout(() => {
        panelChanging = false;
      }, 1000);
    }, 1000);
  };

  function atScroll() {
    const removeFlag = document.querySelector<HTMLElement>(`#aboutTop`);
    if (removeFlag === null) {
      window.removeEventListener(`scroll`, atScroll);
      return;
    }
    let st = pageYOffset;
    if (st - lastScrollTop > 600) {
      window.scrollTo(0, lastScrollTop);
      st = lastScrollTop;
    }
    if (st > value.offsetTop && panelState < 4) {
      window.scrollTo(0, lastScrollTop);
      st = lastScrollTop;
    }
    if (panelChanging) return;
    if (panelState === 1) {
      if (st > lastScrollTop) {
        scrollEvent(`down`);
      }
    }
    if (panelState === 2) {
      if (st > lastScrollTop) {
        scrollEvent(`down`);
      } else if (st < lastScrollTop) {
        scrollEvent(`up`);
      }
    }
    if (panelState === 3) {
      if (st > lastScrollTop) {
        scrollEvent(`down`);
      } else if (st < lastScrollTop) {
        scrollEvent(`up`);
      }
    }
    if (panelState === 4) {
      if (st < innerHeight * 3 - valueBuffer && st < lastScrollTop) {
        scrollEvent(`up`);
        value.classList.remove(`--show`);
      }
    }
    lastScrollTop = st;
  }

  window.addEventListener(`scroll`, atScroll, false);

  function atTouchmove(event) {
    const removeFlag = document.querySelector<HTMLElement>(`#aboutTop`);
    if (removeFlag === null) {
      window.removeEventListener(`touchmove`, atTouchmove);
      return;
    }
    if (!panelChanging) return;
    event.preventDefault();
  }

  window.addEventListener(`touchmove`, atTouchmove, { passive: false });

  function atMousewheel(event) {
    const removeFlag = document.querySelector<HTMLElement>(`#aboutTop`);
    if (removeFlag === null) {
      window.removeEventListener(`mousewheel`, atMousewheel);
      return;
    }
    if (!panelChanging) return;
    event.preventDefault();
  }

  window.addEventListener(`mousewheel`, atMousewheel, { passive: false });
};

export default aboutTop;
