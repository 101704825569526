// ■コンポーネント説明
// 会社情報ナビゲーション表示用コンポーネント

import React, { useEffect } from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import hoverCircle from '@/components/Interaction/hoverCircle';

import * as styles from '@/styles/module/local-nav.module.scss';

export interface CompanyNavigationProps {
  /**
   * 呼び出し元ページの欧文タイトル
   */
  current?: string;
}

const checkCurrent = function (current, titleEn, companyId) {
  if (current === titleEn) {
    return (
      <>
        <li className="box-3rows-01__item button-01 --current">
          <div className="button-01__inner">{titleEn}</div>
        </li>
      </>
    );
  }
  if (current !== titleEn) {
    return (
      <>
        <li className="box-3rows-01__item">
          <Link
            to={`/company/${companyId}/`}
            className="button-01 js-hover-circle"
            data-circle-type="stick-target"
            data-circle-inner="arrow-right-orange"
          >
            <div className="button-01__inner js-stick-target">{titleEn}</div>
            <div className="button-01__mix-blend-lighten" />
          </Link>
        </li>
      </>
    );
  }
  return null;
};

const CompanyNavigation: React.FC<CompanyNavigationProps> = (props) => {
  const { current } = props;
  useEffect(() => {
    hoverCircle();
  }, []);
  const data = useStaticQuery<GatsbyTypes.CompanyNavigationQuery>(
    graphql`
      query CompanyNavigation {
        allMicrocmsCompany(sort: { fields: sort }) {
          edges {
            node {
              id
              companyId
              titleEn
            }
          }
        }
      }
    `,
  );
  return (
    <>
      <nav className={styles.local_nav}>
        <ul className="box-3rows-01 --tab-rows">
          {React.Children.toArray(
            data.allMicrocmsCompany.edges.map(({ node }) =>
              checkCurrent(current, node.titleEn, node.companyId),
            ),
          )}
        </ul>
      </nav>
    </>
  );
};

export default CompanyNavigation;
