import React, { useContext, useEffect } from 'react';
import { PageProps } from 'gatsby';
import SEO from '@/components/seo';
import CompanyNavigation from '@/components/Company/CompanyNavigation';
import UIBreadcrumb from '@/components/UI/UIBreadcrumb';
import aboutTop from '@/components/Interaction/about-top';
import hoverCircle from '@/components/Interaction/hoverCircle';
import { Context } from '@/components/Layout';

import imgWave01 from '@/assets/images/company/img_wave_01.png';
import imgWave02 from '@/assets/images/company/img_wave_02.png';
import imgWave03 from '@/assets/images/company/img_wave_03.png';
import videoMission from '@/assets/video/company/figure_mission.mp4';

const CompanyIndex: React.FC<PageProps<GatsbyTypes.CompanyIndexQuery>> = () => {
  const loading = useContext(Context);
  useEffect(() => {
    hoverCircle();
    aboutTop();
  }, []);

  return (
    !loading && (
      <>
        <SEO title="NRIネットコムについて" />
        <div className="about-wrapper" id="aboutTop">
          <div className="panels-wrapper" data-show-panel="1">
            <section className="panels-container">
              <div id="panel-1" className="panel">
                <div className="panel-1-container">
                  <div className="panel-1-container-lead">
                    <p className="panel-1-container-lead__text">
                      NRIネットコムについて
                    </p>
                    <h1 className="panel-1-container-lead__title">
                      About Netcom
                    </h1>
                  </div>
                  <p className="panel-1-container__text">
                    NRIネットコムは、異なる専門性を持った4つの事業領域によりデジタル戦略の実現を全方位でサポートし、
                    <br className="d-pc-inline" />
                    お客さまのビジネスの継続的な発展に貢献します。
                  </p>
                </div>
                <div className="wave-container --wave01">
                  <img
                    loading="lazy"
                    src={imgWave01}
                    alt=""
                    className="wave-container__img"
                  />
                </div>
                <div className="wave-container --wave02">
                  <img
                    loading="lazy"
                    src={imgWave02}
                    alt=""
                    className="wave-container__img"
                  />
                </div>
                <div className="wave-container --wave03">
                  <img
                    loading="lazy"
                    src={imgWave03}
                    alt=""
                    className="wave-container__img"
                  />
                </div>
              </div>
              <div id="panel-2" className="panel">
                <div className="panel-2-container">
                  <div className="panel-2-container-sub panel-2-container__video">
                    <video autoPlay playsInline loop muted>
                      <source src={videoMission} type="video/mp4" />
                      <p>ご使用のブラウザでは動画再生に対応していません</p>
                    </video>
                  </div>
                  <div className="panel-2-container-sub">
                    <h3 className="panel-2-container-sub__title">Mission</h3>
                    <p className="panel-2-container-sub__text">
                      私たちNRIネットコムは、デジタルを活用し、
                      <br className="d-pc-inline" />
                      社会の明るい「ミライ」に貢献します。
                      <br className="d-pc-inline" />
                      お客さまのビジネスの「ミライ」を共に創ります。
                      <br className="d-pc-inline" />
                      従業員が自身の「ミライ」を実現できる企業をめざします。
                    </p>
                  </div>
                </div>
              </div>
              <div id="panel-3" className="panel">
                <div className="panel-3-container">
                  <h3 className="panel-3-container__title">Vision</h3>
                  <p className="panel-3-container__text">
                    共に歩み、共に創り、
                    <br />
                    めざす「ミライ」に挑み続ける
                  </p>
                </div>
              </div>
            </section>
          </div>
          <section id="value" className="value">
            <div className="value-inner">
              <div className="value__title">Value</div>
              <div className="value-card-container">
                <div
                  className="js-hover-circle"
                  data-circle-type="white"
                  data-circle-inner="card"
                >
                  <div className="value-card --card01">
                    <div className="value-card-inner">
                      <div className="value-card-front">#創造性</div>
                      <div className="value-card-back">
                        いまあるミライを
                        <br />
                        実現するだけでは物足りない、
                        <br />
                        まだ見ぬミライのその先を描く、
                        <br />
                        創る、そして超えていく。
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="js-hover-circle"
                  data-circle-type="white"
                  data-circle-inner="card"
                >
                  <div className="value-card --card02">
                    <div className="value-card-inner">
                      <div className="value-card-front">#自主性</div>
                      <div className="value-card-back">
                        時に迷い、時に苦しみ、
                        <br />
                        進む道は険しいかもしれない、
                        <br />
                        どんな時でも最後に決断するのは
                        <br />
                        誰でもない、自分自身。
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="js-hover-circle"
                  data-circle-type="white"
                  data-circle-inner="card"
                >
                  <div className="value-card --card03">
                    <div className="value-card-inner">
                      <div className="value-card-front">#挑戦</div>
                      <div className="value-card-back">
                        恐れることなく、
                        <br />
                        挑む勇気を持つことこそが
                        <br />
                        壁を越える原動力となる。
                        <br />
                        いま感じている
                        <br />
                        身震いや不安さえも力に変えて、
                        <br />
                        ジャンプしよう。
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="js-hover-circle"
                  data-circle-type="white"
                  data-circle-inner="card"
                >
                  <div className="value-card --card04">
                    <div className="value-card-inner">
                      <div className="value-card-front">#自由</div>
                      <div className="value-card-back">
                        私たちの自由な発想と、
                        <br />
                        あきらめない熱意で
                        <br />
                        やりたいことに、
                        <br />
                        本気で取り組もう
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="js-hover-circle"
                  data-circle-type="white"
                  data-circle-inner="card"
                >
                  <div className="value-card --card05">
                    <div className="value-card-inner">
                      <div className="value-card-front">#助け合い</div>
                      <div className="value-card-back">
                        自立する力を持ち、
                        <br />
                        苦境の仲間には手を差し伸べる。
                        <br />
                        全員の力がチームを強くする。
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="js-hover-circle"
                  data-circle-type="white"
                  data-circle-inner="card"
                >
                  <div className="value-card --card06">
                    <div className="value-card-inner">
                      <div className="value-card-front">#スピード</div>
                      <div className="value-card-back">
                        速さを増せば、
                        <br />
                        これまでにない景色が見えてくる。
                        <br />
                        速さを持続すれば、
                        <br />
                        はるか遠くまで行くことができる、
                        <br />
                        先を目指そう。
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <CompanyNavigation />
        <UIBreadcrumb UpperItem={[]} CurrentItem="About Netcom" />
      </>
    )
  );
};

export default CompanyIndex;
